<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	import themeConfig from '@/../themeConfig.js'

	export default {
		watch: {
			'$store.state.theme'(val) {
				this.toggleClassInBody(val);
			}
		},
		async created() {
			try {
				await this.$auth.renewTokens();
			} catch (e) {
				console.log(e);
			}
		},
		methods: {
			toggleClassInBody(className) {
				if (className == 'dark') {
					if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
					document.body.classList.add('theme-dark');
				} else if (className == 'semi-dark') {
					if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
					document.body.classList.add('theme-semi-dark');
				} else {
					if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
					if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
				}
			},
			handleWindowResize(event) {
				this.$store.dispatch('updateWindowWidth', event.currentTarget.innerWidth);
			},
		},
		mounted() {
			this.toggleClassInBody(themeConfig.theme)
			this.$nextTick(() => {
				window.addEventListener('resize', this.handleWindowResize);
			});
			this.$store.dispatch('updateWindowWidth', window.innerWidth);

			// Check if URL has timestamp
			if(this.getQueryVariable('timestamp')) {
				this.$store.dispatch('setCurrentTimestamp', this.getQueryVariable('timestamp'));
				// Get Spam Report Data (Based on Timestamp)
				this.$store.dispatch("getStoreSpamReport",this.getQueryVariable('timestamp'));
			}
			else {
				// Get Spam Report Data
				this.$store.dispatch("getStoreSpamReport");
			}
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.handleWindowResize);
		},
	}
</script>
